import React, { useState } from 'react';
import { ResourceForm } from 'views/resources/components/resource-form';
import { RESOURCE_FORM_TYPE } from 'views/resources/constants';
import { crossPlatformWhitePaperDialogContent } from 'views/single-resource/cross-platform-white-paper/constants';
import { medicalDataProtectionDialogContent } from 'views/single-resource/medical-data-protection/constants';
import { Dialog } from 'components/dialog';
import { resolveDialogContent } from 'components/dialog/utilities';
import { DialogContent } from 'components/dialog-content';
import { Overlay } from 'components/overlay';
import { FORM_STATUSES } from 'constants/form-statuses';
import { useLoading } from 'hooks/use-loading';
import { object, string } from 'prop-types';

const resolveCustomDialogContent = (openedResourceFormType) => {
    if (openedResourceFormType === RESOURCE_FORM_TYPE.MEDICAL_DATA_PROTECTION) {
        return medicalDataProtectionDialogContent;
    }

    if (
        openedResourceFormType === RESOURCE_FORM_TYPE.CROSS_PLATFORM_WHITE_PAPER
    ) {
        return crossPlatformWhitePaperDialogContent;
    }
};

export const ResourceModalForm = ({ dialogRef, openedResourceFormType }) => {
    const { isLoading, loadingStart, loadingStop } = useLoading();
    const [formStatus, setFormStatus] = useState(FORM_STATUSES.IDLE);

    const updateFormStatus = (status) => setFormStatus(status);

    const onClose = () => {
        if (formStatus === FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR) {
            window.location.reload();
            return;
        }

        dialogRef?.current?.close();
        setFormStatus(FORM_STATUSES.IDLE);
    };

    return (
        <Dialog ref={dialogRef}>
            {formStatus === FORM_STATUSES.IDLE ? (
                <ResourceForm
                    openedResourceFormType={openedResourceFormType}
                    updateFormStatus={updateFormStatus}
                    loadingStart={loadingStart}
                    loadingStop={loadingStop}
                    onClose={onClose}
                />
            ) : (
                <DialogContent
                    {...resolveDialogContent({
                        formStatus,
                        customDialogContent: resolveCustomDialogContent(
                            openedResourceFormType,
                        ),
                    })}
                    firstParagraphMargin
                    onClose={onClose}
                />
            )}
            <Overlay show={isLoading} inComponent />
        </Dialog>
    );
};

ResourceModalForm.propTypes = {
    dialogRef: object.isRequired,
    openedResourceFormType: string.isRequired,
};
