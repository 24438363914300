import { DIALOG_CONTENT } from 'components/dialog/constants';

export const ROOT_PATH = 'medical-data-protection';

export const medicalDataProtectionDialogContent = {
    success: {
        ...DIALOG_CONTENT.SUCCESS,
        title: 'medical-data-protection.dialog.greatThankYou',
        info: 'medical-data-protection.dialog.yourDownload',
        description: '',
    },
};

export const PERCENTAGE_INFO = [
    {
        value: '67%',
        description: `${ROOT_PATH}.theAttackImpactedPatientData`,
    },
    {
        value: '48%',
        description: `${ROOT_PATH}.theAttackImpactedPatientCare`,
    },
    {
        value: '10%',
        description: `${ROOT_PATH}.theAttackDidNotImpact`,
    },
];
