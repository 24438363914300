import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { HeaderSection } from 'views/resources/components/header-section';
import { ResourceItem } from 'views/resources/components/resource-item';
import { ResourceModalForm } from 'views/resources/components/resource-modal-form';
import { RESOURCES } from 'views/resources/constants';
import { useResourcesImages } from 'views/resources/hooks/use-resources-images';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';

const SRecourcesContainer = styled.div`
    display: grid;
    justify-content: center;
    gap: 3rem 1rem;
    grid-template-columns: repeat(3, 1fr);

    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: repeat(2, 1fr);
    `};

    ${CONSTANTS.MEDIA.max700`
        grid-template-columns: 1fr;
        gap: 5rem 1rem;
    `};
`;

export const ResourcesView = () => {
    const { formatMessage } = useIntl();
    const images = useResourcesImages();
    const dialogRef = useRef();

    const [openedResourceFormType, setOpenedResourceFormType] = useState('');

    const openResourceModal = (resourceType) => {
        setOpenedResourceFormType(resourceType);
        dialogRef?.current?.showModal();
    };

    return (
        <>
            <HeaderSection />
            <Container>
                <ResourceItem
                    image={
                        images[`${RESOURCES[0].imageName}`].childImageSharp
                            .gatsbyImageData
                    }
                    imageAlt={formatMessage({
                        id: RESOURCES[0].imageAlt,
                    })}
                    onClick={() =>
                        openResourceModal(RESOURCES[0].resourceFormType)
                    }
                    {...RESOURCES[0]}
                />
                <SRecourcesContainer>
                    {RESOURCES.map((item, index) => {
                        if (index === 0) {
                            return null;
                        }

                        return (
                            <ResourceItem
                                key={index}
                                image={
                                    images[item.imageName].childImageSharp
                                        .gatsbyImageData
                                }
                                imageAlt={formatMessage({
                                    id: item.imageAlt,
                                })}
                                onClick={() =>
                                    openResourceModal(item.resourceFormType)
                                }
                                {...item}
                            />
                        );
                    })}
                </SRecourcesContainer>
            </Container>
            <ResourceModalForm
                dialogRef={dialogRef}
                openedResourceFormType={openedResourceFormType}
            />
        </>
    );
};
