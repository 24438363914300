import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FILE_NAME, RESOURCE_FORM_TYPE } from 'views/resources/constants';
import { useResourceForm } from 'views/single-resource/components/email-form-section/hooks';
import { ResourceFormContent } from 'views/single-resource/components/email-form-section/resource-form-content';
import { func, string } from 'prop-types';
import CrossIcon from 'svgs/icons/ic-red-cross.svg';

const SFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3.125rem 1.625rem 1.625rem;
    max-width: 44.25rem;
`;

const SIconButton = styled.button`
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    align-self: flex-end;
    margin-right: -0.375rem;
    margin-bottom: 1rem;

    > :first-child {
        width: 1.25rem;
        height: 1.25rem;

        > path {
            stroke: var(--black-color);
        }
    }
`;

export const ResourceForm = ({
    openedResourceFormType,
    onClose,
    updateFormStatus,
    loadingStart,
    loadingStop,
}) => {
    const { formatMessage } = useIntl();
    const { control, formState, onSubmit } = useResourceForm({
        updateFormStatus,
        loadingStart,
        loadingStop,
        isEbookDownloadable:
            openedResourceFormType ===
            RESOURCE_FORM_TYPE.MEDICAL_DATA_PROTECTION,
        fileName: FILE_NAME.MEDICAL_DATA_PROTECTION,
    });

    return (
        <SFormContainer>
            <SIconButton
                aria-label={formatMessage({
                    id: 'form-close-alt',
                })}
                onClick={onClose}
            >
                <CrossIcon />
            </SIconButton>
            <ResourceFormContent
                id={openedResourceFormType}
                control={control}
                formState={formState}
                inModal
                onSubmit={onSubmit}
            />
        </SFormContainer>
    );
};

ResourceForm.propTypes = {
    openedResourceFormType: string.isRequired,
    onClose: func.isRequired,
    updateFormStatus: func.isRequired,
    loadingStart: func.isRequired,
    loadingStop: func.isRequired,
};
