import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { RESOURCE_FORM_TYPE } from 'views/resources/constants';
import { Button } from 'components/button-new';
import { HeaderFourth } from 'components/header-fourth';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bool, func, number, object, string } from 'prop-types';

const mainItemContainerStyles = css`
    display: flex;
    gap: 3.125rem;
    margin-bottom: 12.5rem;

    #image-container {
        padding: 2.75rem 1.75rem 2.75rem 2.75rem;
    }

    #content-container {
        justify-content: center;
    }

    ${CONSTANTS.MEDIA.max840`
        flex-direction: column;
    `}
`;

const itemContainerStyles = css`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    #image-container {
        min-width: 20.25rem;
        padding: 1.75rem 0.75rem 1.75rem 1.75rem;

        ${CONSTANTS.MEDIA.max1024`
            max-height: 22.625rem;
        `};
    }
`;

const SRecourceContainer = styled.div`
    ${(props) =>
        props.isMainItem ? mainItemContainerStyles : itemContainerStyles};
`;

const SImageContainer = styled.div`
    display: flex;
    flex: 1;
    background-color: var(--gray-color-90);
    justify-content: center;
`;

const SContentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const SActionsContainer = styled.div`
    display: flex;
    gap: 3rem;
    align-items: center;
`;

const SHeaderFourth = styled(HeaderFourth)`
    color: var(--primary-color);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    font-size: 0.75rem;
`;

const SHeaderThird = styled(HeaderThird)`
    font-size: 1.75rem;
    margin-bottom: 1rem;
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 2rem;
`;

const SLink = styled(PrefixLangLink)`
    text-decoration: unset;
    color: var(--black-text-color);
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
`;

export const ResourceItem = ({
    image,
    imageAlt,
    title,
    descriptionsPath,
    descriptionsCounter,
    isMainItem,
    onClick,
    resourceFormType,
    to,
}) => {
    const renderDescriptions = () =>
        Array.from({ length: descriptionsCounter }, (_, i) => (
            <SParagraph key={i}>
                <FormattedMessage id={`${descriptionsPath}.descriptions.0`} />
            </SParagraph>
        ));

    const isE2EDigitalProduct =
        resourceFormType === RESOURCE_FORM_TYPE.E2E_DIGITAL_PRODUCT;

    return (
        <SRecourceContainer isMainItem={isMainItem}>
            <SImageContainer id="image-container">
                <GatsbyImage alt={imageAlt} image={image} />
            </SImageContainer>
            <SContentContainer id="content-container">
                <SHeaderFourth>
                    <FormattedMessage id="page.topSubTitle" />
                </SHeaderFourth>
                <SHeaderThird>
                    <FormattedMessage id={title} />
                </SHeaderThird>
                {renderDescriptions()}
                <SActionsContainer>
                    <Button
                        variant="outline"
                        disabled={isE2EDigitalProduct}
                        onClick={isE2EDigitalProduct ? undefined : onClick}
                    >
                        <FormattedMessage
                            id={
                                isE2EDigitalProduct
                                    ? 'general.soon'
                                    : 'general.download'
                            }
                        />
                    </Button>
                    {to && (
                        <SLink to={to}>
                            <FormattedMessage id="general.learnMore" />
                        </SLink>
                    )}
                </SActionsContainer>
            </SContentContainer>
        </SRecourceContainer>
    );
};

ResourceItem.propTypes = {
    image: object.isRequired,
    imageAlt: string.isRequired,
    title: string.isRequired,
    descriptionsPath: string.isRequired,
    descriptionCounter: number.isRequired,
    isMainItem: bool.isRequired,
    onClick: func.isRequired,
    resourceFormType: string.isRequired,
    to: string,
};

ResourceItem.defaultProps = {
    isMainItem: false,
    descriptionCounter: 1,
    to: undefined,
};
