import { graphql, useStaticQuery } from 'gatsby';

const RESOURCE_IMAGES = graphql`
    {
        crossPlatformWhitePaper: file(
            relativePath: { eq: "resources/cross-platform.png" }
        ) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    width: 396
                    formats: [WEBP]
                )
            }
        }
        e2eDigitalProduct: file(relativePath: { eq: "resources/e2e.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    width: 314
                    formats: [WEBP]
                )
            }
        }
        medicalDataProtection: file(
            relativePath: { eq: "resources/medical-data.png" }
        ) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    width: 314
                    formats: [WEBP]
                )
            }
        }
    }
`;

export const useResourcesImages = () => {
    const images = useStaticQuery(RESOURCE_IMAGES);
    return images;
};
