import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { Specification } from 'modules/projects/components/specification';

const SContainer = styled(Container)`
    margin: 6rem auto;
`;

export const HeaderSection = () => {
    return (
        <SContainer>
            <Specification
                texts={{
                    topSubTitle: 'page.title',
                    title: 'page.title',
                    subtitle: 'general.haveQuestion',
                }}
            />
        </SContainer>
    );
};
