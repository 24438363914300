import React from 'react';
import { DIALOG_CONTENT } from 'components/dialog/constants';
import CodeIcon from 'svgs/icons/ic-code-alt.svg';
import PenToolIcon from 'svgs/icons/ic-pen-tool-alt.svg';
import PeopleIcon from 'svgs/icons/ic-people-alt.svg';
import SettingsIcon from 'svgs/icons/ic-settings.svg';
import SettingsAltIcon from 'svgs/icons/ic-settings-alt.svg';

export const ROOT_PATH = 'cross-platform-white-paper';

export const TILES_DATA = [
    {
        icon: <PeopleIcon width={42} height={42} />,
        text: `${ROOT_PATH}.reachWiderAudience`,
    },
    {
        icon: <CodeIcon width={42} height={42} />,
        text: `${ROOT_PATH}.useLessCode`,
    },
    {
        icon: <SettingsIcon width={42} height={42} />,
        text: `${ROOT_PATH}.increaseFlexibility`,
    },
    {
        icon: <PenToolIcon width={42} height={42} />,
        text: `${ROOT_PATH}.designFaster`,
    },
    {
        icon: <SettingsAltIcon width={42} height={42} />,
        text: `${ROOT_PATH}.maintainEasier`,
    },
];

export const crossPlatformWhitePaperDialogContent = {
    success: {
        ...DIALOG_CONTENT.SUCCESS,
        title: `${ROOT_PATH}.dialog.title`,
        info: `${ROOT_PATH}.dialog.info`,
        description: `${ROOT_PATH}.dialog.description`,
    },
};
